<template>
    <div
        v-show="notificationStore.show_cookies_advice"
        class="cookies-consent fixed bottom-[0px] left-0 z-[1002] w-full border-b border-gray-200 bg-gray-200 px-6 py-[12px] dark:border-gray-500 dark:bg-gray-600">
        <div class="flex flex-row items-center justify-center">
            <p class="text-sm text-gray-500 dark:text-gray-400">
                By using this website, you agree to our
                <a
                    :href="route('cookiesPolicy')"
                    class="text-sm text-blue-500 dark:text-gray-100 dark:hover:text-blue-400"
                    target="_blank"
                    >Cookie Policy </a
                >. We use cookies to deliver our services.
            </p>
            <div v-if="!showCookieOptions" class="">
                <div
                    class="ml-5 cursor-pointer rounded-lg border border-mintgreen px-4 py-2 text-xs uppercase text-mintgreen hover:bg-blue-100 dark:text-mintgreen dark:hover:bg-mintgreen dark:hover:text-black"
                    @click="setOFFCokies('MOCK_ESSENTIAL_COOKIES', '12345678')">
                    OK
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { defineComponent, onMounted, ref } from 'vue'
    import { useNotificationStore } from '@/store/notification'
    import Checkbox from '@/Components/Checkbox.vue'

    defineComponent({
        name: 'AppCookieConsent',
    })
    const notificationStore = useNotificationStore()
    const essentialsCookies = ref(true)
    const nonEssentialsCookies = ref(false)

    onMounted(() => {
        getCookieConsent()
    })
    const showCookieOptions = ref(false)
    const cookieOptions = () => {
        showCookieOptions.value = true
    }

    function setOFFCokies(cname, cvalue) {
        document.cookie = cname + '=' + cvalue + ';' + ';path=/'
        notificationStore.setCookieConsent()
        localStorage.setItem('mockCookieConsent', 'saved')
    }

    function getCookieConsent() {
        let doc_cookie = document.cookie
        const lsCookie = localStorage.getItem('mockCookieConsent') === 'saved'
        const isCookie = doc_cookie.includes('MOCK_ESSENTIAL_COOKIES')
        if (isCookie && lsCookie) {
            notificationStore.show_cookies_advice = false
        } else if (lsCookie && !isCookie) {
            setOFFCokies('MOCK_ESSENTIAL_COOKIES', '12345678')
        } else {
            notificationStore.show_cookies_advice = true
        }
    }
</script>
