import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        //cookies
        is_all_cookies_accepted: false,
        show_cookies_advice: true,
    }),

    actions: {
        setCookieConsent(data) {
            this.show_cookies_advice = false
        },
    },
})
